import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { StoreService } from '@services/store.service';

export const AuthGuardNoOrgFn: CanActivateFn = (_, state) => {
    const store: StoreService = inject(StoreService);
    const us = store.getState('user');
    const au = us.isLoggedIn() && !us.isGuest;
    return au
        ? true
        : inject(Router).createUrlTree(['/login'], { queryParams: { url: state.url }});
};
