import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { StoreService } from '@services/store.service';

export const AuthGuardFn: (priv?: string) =>  CanActivateFn = (priv) => {
    return (route, state) => {
        const store: StoreService = inject(StoreService);
        const us = store.getState('user');
        const au = us.isLoggedIn() && !us.isGuest;
        // console.log('=[AuthGuardFn]===>', 'us:', us, 'au:', au, 'route:', route);
        if (!au) {
            return  inject(Router).createUrlTree(['/login'], { queryParams: { url: state.url }});
        }
        let granted = true;
        // console.log('=[AuthGuardFn]===>', '\t priv:', priv);
        if (priv) {
            let g = store.granted.getValue();
            // console.log('=[AuthGuardFn]===>', '\t g:', JSON.stringify(g));
            if (g._notReady) {
                const orgId = route?.root?.firstChild?.firstChild?.params?.id;
                // console.log('=[AuthGuardFn]===>', '\t privs not loaded yet, check url for orgId:', orgId);
                if (orgId) {
                    store.activeOrgId.next(orgId);
                    store.updateGrantedPrivs();
                    g = store.granted.getValue();
                }
            }
            granted = g?.global?.[priv];
        }
        // console.log('=[AuthGuardFn]===>', '\t granted:', priv);
        return !!(au && us.user?.orgs?.length && granted);
    }
};
